import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import TimeIcon from '../../../static/assets/timeIcon.svg';
import MostPopularIcon from '../../../static/assets/mostPopularWhite.svg';
const BlogPostComponent = (props) =>{
    const {
      blogArticleStudentsImage,
      blogArticleAdministrationImage,
      blogArticleEncourageImage,
    } = props;
    return (
      <div className="full-container">
        <div className="blog-article-section-popular">
          <p className="blog-article-section-popular-subtitle">
            <span className="blog-article-section-popular-subtitle-icon">
              <MostPopularIcon />
            </span>
            Most Popular
          </p>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/cultivating-the-start-up-and-innovation-mindset-in-college"
              >
                <GatsbyImage
                  alt="blogArticleStudentsImage"
                  image={blogArticleStudentsImage}
                  className="blog-article-section-popular-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      15 Sep 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      5 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Cultivating the startup and innovation mindset in college
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/how-leaders-can-encourage-their-employees-to-share-their-ideas"
              >
                <GatsbyImage
                  alt="blogArticleEncourageImage"
                  image={blogArticleEncourageImage}
                  className="blog-article-section-popular-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      16 Jun 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How leaders can encourage their employees to share their
                    ideas
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/creativity-in-innovation-and-change-management"
              >
                <GatsbyImage
                  alt="blogArticleAdministrationImage"
                  image={blogArticleAdministrationImage}
                  className="blog-article-section-popular-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      11 Feb 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      5 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why Employees Stop Sharing Ideas
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}
export default BlogPostComponent;
import React from "react";
import Header from "../components/headerComponent";
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import BlogHomeComponent from "../components/Blog/blogHomeComponent";
import BlogPostComponent from '../components/Blog/blogPostComponent';
import NewFooterComponent from '../components/newFooterComponent';
import BlogCategoriComponent from "../components/Blog/blogCategoriComponent";
const categoriItem = [
"All Categories",
'Change Management',
'Innovation',
'Transformation',
'Idea Management'
]

const Blog = (props) =>{
   const {
     data: {
       wpPage: {
         seo,
         newBlogHomeSection: {
           newBlogHomeSectionTitle,
           newBlogHomeSectionArticleTitle,
           newBlogHomeSectionTime,
           newBlogHomeSectionLink,
           newBlogHomeSectionDescription,
           newBlogHomeSectionImage: {
             localFile: {
               childImageSharp: { gatsbyImageData: newBlogHomeSectionImage },
             },
           },
         },
         blogMostPopularArticleSection: {
           blogPopularArticleSectionData,
           blogPopularArticleSectionTime,
           blogPopularArticleSectionTitle,
           blogPopularArticleAdministrationSectionImage: {
             localFile: {
               childImageSharp: {
                 gatsbyImageData: blogPopularArticleAdministrationSectionImage,
               },
             },
           },
           blogPopularArticleEncourageSectionImage: {
             localFile: {
               childImageSharp: {
                 gatsbyImageData: blogPopularArticleEncourageSectionImage,
               },
             },
           },
          //  blogPopularArticleSectionImage: {
          //    localFile: {
          //      childImageSharp: {
          //        gatsbyImageData: blogPopularArticleSectionImage,
          //      },
          //    },
          //  },
           blogPopularArticleStopSharingIdeaSectionImage: {
             localFile: {
               childImageSharp: {
                 gatsbyImageData: blogPopularArticleStopSharingIdeaSectionImage,
               },
             },
           },
           blogPopularArticleStrategyImage: {
             localFile: {
               childImageSharp: {
                 gatsbyImageData: blogPopularArticleStrategyImage,
               },
             },
           },
           blogPopularArticleStudentsSectionImage: {
             localFile: {
               childImageSharp: {
                 gatsbyImageData: blogPopularArticleStudentsSectionImage,
               },
             },
           },
         },
       },
     },
     pageContext: { title },
   } = props;
    return (
      <Layout title={title} seo={seo}>
        <Header />
        <BlogHomeComponent
          blogHomeTitle={newBlogHomeSectionArticleTitle}
          blogHomeDescription={newBlogHomeSectionDescription}
          blogHomeArticleImage={newBlogHomeSectionImage}
          blogHomeArticleData="16 Oct 2022"
          blogHomeArticleTime={newBlogHomeSectionTime}
          blogHomeArticleTitle={newBlogHomeSectionTitle}
          blogHomeArticleLink={newBlogHomeSectionLink}
        />
        <BlogPostComponent
          blogArticleStudentsImage={blogPopularArticleStudentsSectionImage}
          blogArticleAdministrationImage={
            blogPopularArticleAdministrationSectionImage
          }
          blogArticleEncourageImage={blogPopularArticleEncourageSectionImage}
          blogArticleStopSharingIdeasImage={
            blogPopularArticleStopSharingIdeaSectionImage
          }
          blogArticleStrategyImage={blogPopularArticleStrategyImage}
          blogArticleData={blogPopularArticleSectionData}
          blogArticleTime={blogPopularArticleSectionTime}
          blogArticleTitle={blogPopularArticleSectionTitle}
        />
        <BlogCategoriComponent
          categoriItem={categoriItem}
          blogArticleAdministrationImage={
            blogPopularArticleAdministrationSectionImage
          }
        />
        <NewFooterComponent isProduct={true} />
      </Layout>
    );
}
export const query = graphql`
  query {
    wpPage(slug: { eq: "blog-page" }) {
      seo {
        metaDesc
        title
      }
      newBlogHomeSection {
        newBlogHomeSectionTitle
        newBlogHomeSectionArticleTitle
        newBlogHomeSectionTime
        newBlogHomeSectionLink
        newBlogHomeSectionDescription
        newBlogHomeSectionData
        newBlogHomeSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    blogMostPopularArticleSection {
      blogPopularArticleSectionData
      blogPopularArticleSectionTime
      blogPopularArticleSectionTitle
     blogPopularArticleAdministrationSectionImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      blogPopularArticleEncourageSectionImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      blogPopularArticleSectionImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      blogPopularArticleStopSharingIdeaSectionImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      blogPopularArticleStrategyImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      blogPopularArticleStudentsSectionImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    }
  }
`;
export default Blog
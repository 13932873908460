import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import TimeIcon from '../../../static/assets/timeIcon.svg';
import { useMutation, gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';


const SUBMIT_CONTACT = gql`
  mutation contact($data: ContactUsFormInput!) {
    submitContact(data: $data) {
      errors
    }
  }
`;
const BlogHomeComponent = (props) =>{
    const {
        blogHomeTitle,
        blogHomeDescription,
        blogHomeArticleImage,
        blogHomeArticleData,
        blogHomeArticleTime,
        blogHomeArticleTitle,
        blogHomeArticleLink
    } = props;
      const {
        register,
        handleSubmit,
      } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {},
      });
      const [contact, { loading }] = useMutation(SUBMIT_CONTACT);
      const isLoading = loading;
      const onSubmitHandler = values => {
        const { username } = values;
        if (username) {
          navigate(
            'https://cdn.forms-content.sg-form.com/58c49499-4be2-11ed-ab1b-463471d968c7'
          );
             navigate(
            'https://cdn.forms-content.sg-form.com/58c49499-4be2-11ed-ab1b-463471d968c7'
          );
        }

        const data = {
          email: username,
        };
        contact({ variables: { data } })
          .then(response => {
            console.log(response);
            toast.success('Message sent successfully', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
          })
      };
    return (
      <div className="blog-home-section">
        <div className="full-container">
          <div className="blog-home-section-container">
            <div className="row">
              <div className="col-md-6 blog-home-section-col">
                <div className="blog-home-section-wrapper">
                  <h1 className="blog-home-section-wrapper-title">
                    {blogHomeTitle}
                  </h1>
                  <p className="blog-home-section-wrapper-description">
                    {blogHomeDescription}
                  </p>
                  <form
                    className="new-home-hero-section-input-section"
                    onSubmit={handleSubmit(onSubmitHandler)}
                  >
                    <input
                      type="email"
                      name="email"
                      id="email"
                      disabled={isLoading}
                      {...register('username')}
                      placeholder="Enter your email"
                      className="new-home-hero-section-input"
                    />
                    <button
                      aria-label="sign up"
                      type="submit"
                      className="new-home-hero-section-button"
                    >
                      Sign Up
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <Link
                  className="blog-home-section-article"
                  to="/blog/creativity-in-innovation-and-change-management"
                >
                  <GatsbyImage
                    alt={'blogHomeArticleImage'}
                    image={blogHomeArticleImage}
                    className="blog-home-section-article-image"
                  />
                  <div className="blog-home-section-article-wrapper">
                    <div className="blog-home-section-article-publicationTimer">
                      <p className="blog-home-section-article-data">
                        {blogHomeArticleData}
                      </p>
                      <p className="blog-home-section-article-time">
                        <span className="blog-home-section-article-time-icon">
                          <TimeIcon />
                        </span>
                        {blogHomeArticleTime}
                      </p>
                    </div>
                    <h2 className="blog-home-section-article-title">
                      {blogHomeArticleTitle}
                    </h2>
                    <p className="blog-home-section-article-link">
                      {blogHomeArticleLink}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default BlogHomeComponent
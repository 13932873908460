import React, { useState } from 'react';
import { Link } from 'gatsby';
import TimeIcon from '../../../static/assets/timeIcon.svg';
import BlogAllPostComponent from './blogAllProstComponent';
import StrategyImage from '../../images/strategy.png';
import StopShareIdeaImage from '../../images/stopShareIdea.png';
import ShareIdeaImage from '../../images/shareIdeaArticle.png';
import StudetsImage from '../../images/students.png';
import ProfesorssImage from '../../images/professor.png';
import AdministrationImage from '../../images/administation.png';
import InovationImage from '../../images/inovationArticle.png';
import ConsultingImage from '../../images/consultingBlogImage.png';
import InnovationImage from '../../images/innovationBlogImage.png';
import BuildingConsensusImage from '../../images/buildingConsensusBlogImage.png';
import BlogArticleImportanceOfLeveragingImage from '../../images/two-young-business-partners.png';
import BlogAgreedSocializeIdeadImage from '../../images/young-it-specialists-works-on-computers.png';
import BlogAgreedHelpBusinessesImage from '../../images/portrait-of-young-businesspeople-working.png';

const BlogCategoriComponent = props => {
  const { categoriItem } = props;
  const [isActive, setIsActive] = useState(0);

  const postItem = {
    0: {
      Comment: <BlogAllPostComponent />,
    },
    1: {
      Comment: (
        <div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/promote-dei"
              >
                <img
                  src={BlogArticleImportanceOfLeveragingImage}
                  alt="student"
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      14 Feb 2023
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    The importance of leveraging technology to promote diversity
                    and inclusion in the workplace
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/decision-making-process"
              >
                <img
                  alt="administartin"
                  src={BlogAgreedHelpBusinessesImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      2 Feb 2023
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How Agreed can help businesses with their decision-making
                    process
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/build-consensus"
              >
                <img
                  alt="inovation"
                  src={BuildingConsensusImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      19 Dec 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why Building Consensus For Internal Initiatives Is Crucial
                    For Project Execution
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/creativity-in-innovation-and-change-management"
              >
                <img
                  alt="inovation"
                  src={InovationImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      16 Oct 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      9 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Creativity in Innovation and Change Management
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/from-strategy-to-delivery-closing-the-gap"
              >
                <img
                  alt="strategy"
                  src={StrategyImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      23 Mar 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      9 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    From Strategy to Delivery - closing the gap.
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/how-leaders-can-encourage-their-employees-to-share-their-ideas"
              >
                <img
                  alt="shareIdea"
                  src={ShareIdeaImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      16 Jun 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How leaders can encourage their employees to share their
                    ideas
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/why-employees-stop-sharing-ideas"
              >
                <img
                  alt="stopShareIdea"
                  src={StopShareIdeaImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      11 Feb 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      5 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why Employees stop sharing ideas
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
        </div>
      ),
    },
    2: {
      Comment: (
        <div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/the-importance-of-giving-everyone-a-voice-to-innovate "
              >
                <img
                  src={InnovationImage}
                  alt="student"
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      12 Jan 2023
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    The Importance Of Giving Everyone A Voice To Innovate
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/why-consulting-firms-should-leverage-technology-for-facilitating-internal-initiatives-with-partners"
              >
                <img
                  alt="administartin"
                  src={ConsultingImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      10 Nov 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      2 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why Consulting Firms Should Leverage Technology For
                    Facilitating Internal Initiatives With Partners
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/why-building-consensus-for-internal-initiatives-is-crucial-for-project-execution"
              >
                <img
                  alt="inovation"
                  src={BuildingConsensusImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      19 Dec 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why Building Consensus For Internal Initiatives Is Crucial
                    For Project Execution
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/creativity-in-innovation-and-change-management"
              >
                <img
                  alt="inovation"
                  src={InovationImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      16 Oct 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      9 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Creativity in Innovation and Change Management
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/cultivating-the-start-up-and-innovation-mindset-in-college"
              >
                <img
                  alt="students"
                  src={StudetsImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      15 Sep 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      5 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Cultivating the start-up and innovation mindset in collage
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/from-strategy-to-delivery-closing-the-gap"
              >
                <img
                  alt="strategy"
                  src={StrategyImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      23 Mar 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      9 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    From Strategy to Delivery - closing the gap.
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/how-to-encourage-inclusivity-and-innovation-in-your-college-class"
              >
                <img
                  alt="profesorss"
                  src={ProfesorssImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      08 May 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How to encourage inclusivity and innovation in your college
                    class
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ),
    },
    3: {
      Comment: (
        <div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/build-consensus"
              >
                <img
                  alt="inovation"
                  src={BlogAgreedSocializeIdeadImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      8 Feb 2023
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How to use Agreed to socialize ideas, build consensus,
                    mitigate risks, and create value
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/decision-making-process"
              >
                <img
                  alt="administartin"
                  src={BlogAgreedHelpBusinessesImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      2 Feb 2023
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How Agreed can help businesses with their decision-making
                    process
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/why-consulting-firms-should-leverage-technology-for-facilitating-internal-initiatives-with-partners"
              >
                <img
                  alt="administartin"
                  src={ConsultingImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      10 Nov 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      2 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why Consulting Firms Should Leverage Technology For
                    Facilitating Internal Initiatives With Partners
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/why-idea-sharing-in-universities-promotes-diversity-and-inclusion"
              >
                <img
                  alt="administration"
                  src={AdministrationImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      21 Jul 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      4 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why idea sharing in universities promotes diversity and
                    inclusion
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
        </div>
      ),
    },
    4: {
      Comment: (
        <div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/promote-dei"
              >
                <img
                  src={BlogArticleImportanceOfLeveragingImage}
                  alt="student"
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      14 Feb 2023
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    The importance of leveraging technology to promote diversity
                    and inclusion in the workplace
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/build-consensus"
              >
                <img
                  alt="inovation"
                  src={BlogAgreedSocializeIdeadImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      8 Feb 2023
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How to use Agreed to socialize ideas, build consensus,
                    mitigate risks, and create value
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/the-importance-of-giving-everyone-a-voice-to-innovate "
              >
                <img
                  src={InnovationImage}
                  alt="student"
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      12 Jan 2023
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    The Importance Of Giving Everyone A Voice To Innovate
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/how-to-encourage-inclusivity-and-innovation-in-your-college-class"
              >
                <img
                  alt="profesorss"
                  src={ProfesorssImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      08 May 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How to encourage inclusivity and innovation in your college
                    class
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/cultivating-the-start-up-and-innovation-mindset-in-college"
              >
                <img
                  alt="students"
                  src={StudetsImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      15 Sep 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      5 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Cultivating the start-up and innovation mindset in collage
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/why-idea-sharing-in-universities-promotes-diversity-and-inclusion"
              >
                <img
                  alt="dministartion"
                  src={AdministrationImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      21 Jul 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      4 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why idea sharing in universities promotes diversity and
                    inclusion
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/how-leaders-can-encourage-their-employees-to-share-their-ideas"
              >
                <img
                  alt="shareIdea"
                  src={ShareIdeaImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      16 Jun 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      3 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    How leaders can encourage their employees to share their
                    ideas
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className="blog-article-section-post"
                to="/blog/why-employees-stop-sharing-ideas"
              >
                <img
                  alt="stopShareIdea"
                  src={StopShareIdeaImage}
                  className="blog-article-section-image"
                />
                <div className="blog-article-section-post-card">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      11 Feb 2022
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      5 min
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    Why Employees stop sharing ideas
                  </p>
                  <p className="blog-article-section-post-card-link">
                    Read More
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      ),
    },
  };
  return (
    <div className="full-container">
      <div className="blog-categori-section">
        <ul className="blog-categori-section-list">
          {categoriItem &&
            categoriItem.map((item, index) => {
              return (
                <li key={index} className="blog-categori-section-list-item">
                  <button
                    aria-label="list"
                    className={`blog-categori-section-list-item-button ${
                      index === isActive
                        ? 'blog-categori-section-list-item-button-active'
                        : ''
                    }`}
                    onClick={() => setIsActive(index)}
                  >
                    {item}
                  </button>
                </li>
              );
            })}
        </ul>
        <div className="blog-categori-section-posts">
          {postItem[isActive].Comment}
        </div>
      </div>
    </div>
  );
};
export default BlogCategoriComponent;
